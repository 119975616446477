import {
  Text,
  Video,
  Image,
  MultipleChoice,
  MultipleCorrects,
} from "@taskalliance/task-component-library";
import { useParams } from "react-router-dom";
import { useContent } from "../../content-context";
import { PortableText } from "@portabletext/react";

function Article() {
  let { articleId } = useParams();
  const { getItemById } = useContent();
  const contentRef = useContent();

  if (articleId === undefined) return <></>;

  let item = getItemById(articleId);

  console.log(item);

  if (contentRef.isError) {
    return (
      <div>
        <p>Something is wrong. Can't fetch data.</p>
        <p>Check projectId, dataset name and query.</p>
      </div>
    );
  }

  const customComponents = {
    types: {
      image: ({ value }: any) => (
        <figure className="textpageImage">
          <img src={value.url} alt={value.alt} />
        </figure>
      ),
    },
    block: {
      h1: ({ children }: any) => <h1>{children}</h1>,
    },
  };

  return (
    <>
      <Text>
        Prod id: {articleId} {item?.id}{" "}
      </Text>
      {item?.contents.map((content, index) => {
        if (content.fieldType.toLowerCase() === "text")
          return (
            <PortableText
              key={index}
              value={content.BlockContent}
              components={customComponents}
            />
          );
        else if (content.fieldType.toLowerCase() === "video")
          return (
            <Video
              key={index}
              {...content}
              //callbackEnded={contentRef.navigateItemOffset(1)}
            />
          );
        else if (content.fieldType.toLowerCase() === "image")
          return <Image key={index} {...content} />;
        else if (content.fieldType.toLowerCase() === "mchoice")
          return (
            <MultipleChoice
              key={index}
              {...content}
              toggleInstruction={true}
              hideSubmitBtn={true}
              goToNext={() => contentRef.navigateItemOffset(1)}
            />
          );
        else if (content.fieldType.toLowerCase() === "mccorrects") {
          return (
            <MultipleCorrects
              key={index}
              {...content}
              toggleInstruction={true}
              hideSubmitBtn={true}
              goToNext={() => contentRef.navigateItemOffset(1)}
            />
          );
        } else return null;
      })}
    </>
  );
}

export default Article;
