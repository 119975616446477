import { Button } from "@taskalliance/task-component-library";
import { useContent } from "../../content-context";

function Aside() {
  const { storeLanguage } = useContent();

  const closeMenu = () => {
    document.querySelector("#root>aside")?.classList.remove("open");
  };

  const switchLanguage = (lang: string) => {
    storeLanguage(lang);
    setTimeout(() => {
      closeMenu();
    }, 1000);
  };

  return (
    <aside id="sidenav-open">
      <div></div>
      <nav>
        <p style={{ textAlign: "right", marginRight: "-3rem" }}>
          <Button type={"button"} variant={"ghost"} onClick={closeMenu}>
            <svg
              viewBox="0 0 10 10"
              width="40"
              height="40"
              role="presentation"
              focusable="false"
              aria-label="Close menu"
            >
              <path d="M2,2 L8,8" />
              <path d="M2,8 L8,2" />
            </svg>
          </Button>
        </p>
        <h4>Meny</h4>
        <a href="#">Til hovedmenyen</a>
        <a href="#">Avslutt</a>
        <a href="#">Logg ut</a>
        <Button
          style={{ marginBottom: "1rem" }}
          onClick={() => switchLanguage("no_NB")}
        >
          Norsk
        </Button>
        <Button onClick={() => switchLanguage("en_US")}>English</Button>
      </nav>
    </aside>
  );
}

export default Aside;
