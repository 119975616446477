import { Outlet } from "react-router-dom";

function Articles() {
  return (
    <article>
      <Outlet />
    </article>
  );
}

export default Articles;