import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Aside from "./Aside";
import { useContent } from "../../content-context";

//-------------------
function Main() {
  // uppdating currentItemId based on url param
  const { updateCurrentItemById } = useContent();
  let { articleId } = useParams();
  useEffect(() => {
    if (articleId !== undefined) updateCurrentItemById(articleId);
  }, [articleId, updateCurrentItemById]);

  return (
    <>
      <main>
        <Header />
        <Outlet />
        <Footer />
      </main>
      <Aside />
    </>
  );
}

export default Main;
