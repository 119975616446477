/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./theme.scss";
import "./global.scss";
import { ContentProvider } from "./content-context";
import { HashRouter, Routes, Route } from "react-router-dom";
import Articles from "./components/other/Articles";
import Main from "./components/other/Main";
import StartPage from "./components/other/StartPage";
import Article from "./components/other/Article";
import Tracker from "@openreplay/tracker";

const tracker = new Tracker({
  projectKey: "enPMDWrnc2Ptl7M5XCb1",
  ingestPoint: "https://openreplay.tlms.no/ingest",
});
tracker.start();

function App() {
  return (
    <HashRouter>
      <ContentProvider>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<StartPage />} />
            <Route path="articles" element={<Articles />}>
              <Route path=":articleId" element={<Article />} />
            </Route>
          </Route>
        </Routes>
      </ContentProvider>
    </HashRouter>
  );
}
export default App;
