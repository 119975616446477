import { Button, Text } from "@taskalliance/task-component-library";
import { useContent } from "../../content-context";

function Header() {
  console.log("Header rendered");
  const { getCurrentItem } = useContent();
  let title = getCurrentItem()?.title;

  return (
    <header>
      <Text as="h1" size="small">
        {title}
      </Text>
      <Button
        type={"button"}
        variant={"ghost"}
        onClick={() => {
          document.querySelector("#root>aside")?.classList.add("open");
        }}
      >
        <svg
          viewBox="0 0 100 75"
          width="40"
          height="40"
          role="presentation"
          focusable="false"
          aria-label="Open menu"
        >
          <rect width="100" height="15" rx="8"></rect>
          <rect y="30" width="100" height="15" rx="8"></rect>
          <rect y="60" width="100" height="15" rx="8"></rect>
        </svg>
      </Button>
    </header>
  );
}

export default Header;
