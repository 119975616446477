const sanityQuery = `*[_type == "StructureCourse" && _lang == $lang]{
    ...,
    items[]{
        _type == "PageText" => {
      id,
      "contents": [
        {
          fieldType, 
          BlockContent[]{
            _type == "block" => {
              ...
            },
            _type == "image" => {
              ...,
              "url": asset->url
            }
          }
        }]
    },
     _type == "PageVideoMux" => {
       id,
       title,
       "contents": [
        {
          fieldType, 
          playerType,
          "srcVideo": video.asset->playbackId
        }]
     },
        _type == "PageMultipleChoice" => {
        id,
        "contents": [{
          fieldType, 
          choices[]{
            ...,
            "id": _key
          }, 
          image {
            alt,
            imageWidth,
            "url": asset->url
          }, 
          instructions, instructionPosition, question, submitLabel, btnNext, btnRetry, headingInCorrect, headingCorrect
        }]
     },
       _type == "PageMultiCorrects" => {
        id,
        "contents": [{
            fieldType, 
            choices[]{
              ...,
              "id": _key
            }, 
            image {
              alt,
              imageWidth,
              "url": asset->url
            }, 
            instructions, instructionPosition, question, feedbacks, submitLabel, btnNext, btnRetry, headingInCorrect, headingCorrect
          }]
       }
    }
  }`;

export default sanityQuery;
