import {
  Button,
  Progress,
} from "@taskalliance/task-component-library";
import { useLocation, useNavigate } from "react-router-dom";
import { useContent } from "../../content-context";


function Footer() {
	//const { getNextItem, getPreviousItem } = useContent();
	const contentRef = useContent();

	let nextItemId = contentRef.getNextItem()?.id;
	let previousItemId = contentRef.getPreviousItem()?.id;
	let nextPath = "/articles/" + nextItemId;
	let previousPath = "/articles/" + previousItemId;
	let navigate = useNavigate();
	let location = useLocation();

	return (
		<footer>
			{location.pathname !== "/" ? (
				<Button
					type={"button"}
					variant={"ghost"}
					onClick={() => {
						contentRef.navigateItemOffset(-1);
					}}
				>
				&lt;
				</Button>
			) : (
				<Button type={"button"} variant={"ghost"} disabled={true}>
					&lt;
				</Button>
			)}
			<Progress
				stepCurrent={contentRef.getCurrentItemIndex()+1}
				stepMax={contentRef.items.length}
				progressType="NAV_DOTS"
				callbackNavigateByIndex={contentRef.NavigateItemIndex}
				containerPosition="relative"
			></Progress>
			
			{nextItemId !== undefined ? (
				<Button
					type={"button"}
					variant={"ghost"}
					onClick={() => {
						contentRef.navigateItemOffset(1);
					}}
				>
					&gt;
				</Button>
			) : (
				<Button type={"button"} variant={"ghost"} disabled={true}>
					&gt;
				</Button>
			)}
		</footer>
	);
}

export default Footer;