import { Link } from "react-router-dom";
import { useContent } from "../../content-context";

function StartPage() {
  const { items } = useContent();

  return (
    <article>
      <p>Startsiden</p>
      <br />
      <br />
      {items.length > 0 ? (
        <p>
          <Link to={`articles/${items[0].id}`}>Sanity kurs</Link>
        </p>
      ) : (
        <p>No data exist</p>
      )}
    </article>
  );
}

export default StartPage;
