import React, {
  createContext,
  useState,
  useEffect,
  FunctionComponent,
  useContext,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
// import articles from "./content/testmodule.json";

import useSWR from "swr";
import groq from "groq";
import client from "./client";
import sanityQuery from "./content/sanityQueries";

interface ContentItemInterface {
  id: string;
  title?: string;
  contents: any[];
}

type ContentContextType = {
  items: ContentItemInterface[];
  isError: boolean;
  language: string;
  getItemById: (id: string) => ContentItemInterface | undefined;
  getCurrentItem: () => ContentItemInterface | undefined;
  getCurrentItemIndex: () => number;
  getNextItem: () => ContentItemInterface | undefined;
  getPreviousItem: () => ContentItemInterface | undefined;
  updateCurrentItemById: (id: string) => ContentItemInterface | undefined;
  navigateItemOffset: (indexOffset: number) => void;
  NavigateItemID: (id: string) => void;
  NavigateItemIndex: (itemIndex: number) => void;
  storeLanguage: (lang: string) => void;
};

const ContentContext = createContext<ContentContextType | undefined>(undefined);

function useContent() {
  const context = React.useContext(ContentContext);
  if (!context) {
    throw new Error(`useContent must be used within a ContentProvider`);
  }
  return context;
}

type ProviderProps = React.PropsWithChildren<{}>;

function ContentProvider({ children, ...props }: ProviderProps) {
  const [items, setItems] = useState<ContentItemInterface[]>([]);
  const [currentItemId, setCurrentItemId] = useState(items[0]?.id);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [isError, setIsError] = useState<boolean>(false);

  // Get or set language
  // supported languages, [0] is default
  const languages = ["no_NB", "en_US"];

  const [language, setLanguage] = useState<string>(() => {
    if (typeof window !== "undefined") {
      const localValue = localStorage.getItem("language") || "";
      const activeLanguage =
        localValue && languages.includes(localValue)
          ? localValue
          : languages[0];
      return activeLanguage;
    } else {
      // default language
      return languages[0];
    }
  });

  const storeLanguage = (lang: string) => {
    if (lang && typeof window !== "undefined") {
      localStorage.setItem("language", JSON.stringify(lang));
      setLanguage(lang);
    }
    return;
  };

  // Query data with selected language
  const { data, error } = useSWR(
    groq`${sanityQuery}`,
    (query) => client.fetch(query, { lang: language }),
    { revalidateOnFocus: false, refreshInterval: 10000 }
  );

  useEffect(() => {
    if (error) {
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setItems(data[0].items);
    }
  }, [data]);

  let navigate = useNavigate();

  const getItemById = useCallback(
    (id: string) => {
      const item = items.find((item) => item.id === id);
      return item;
    },
    [items]
  );

  const getItemIndexById = useCallback(
    (itemID: string) => {
      const itemIndex = items.findIndex((item) => item.id === itemID);
      return itemIndex;
    },
    [items]
  );

  const getCurrentItem = useCallback(() => {
    const item = getItemById(currentItemId);
    return item;
  }, [currentItemId, getItemById]);

  const updateCurrentItemById = useCallback(
    (id: string) => {
      setCurrentItemId(id);
      setCurrentItemIndex(getItemIndexById(id));
      const item = getItemById(id);
      return item;
    },
    [getItemById]
  );

  const getCurrentItemIndex = useCallback(() => {
    console.log(
      "getCurrentItemIndex=" +
        currentItemIndex +
        ", currentItemId=" +
        currentItemId
    );
    return currentItemIndex;
  }, [currentItemIndex]);

  const getNextItem = useCallback(() => {
    let currentIndex = items.findIndex((item) => item.id === currentItemId);
    const nextItem = items[currentIndex + 1];
    return nextItem;
  }, [items, currentItemId]);

  const getPreviousItem = useCallback(() => {
    let currentIndex = items.findIndex((item) => item.id === currentItemId);
    const previousItem = items[currentIndex - 1];
    return previousItem;
  }, [items, currentItemId]);

  function NavigateItemID(newItemID: string) {
    //updateCurrentItemById(newItemID);
    navigate("/articles/" + newItemID);
    console.log("content-context.NavigateItemID: newitemid=" + newItemID);
  }

  function NavigateItemIndex(newIndex: number) {
    let currentItem = items[newIndex];
    NavigateItemID(currentItem.id);
  }

  const navigateItemOffset = useCallback(
    (indexOffset: number) => {
      let currentIndex: number = items.findIndex(
        (item) => item.id === currentItemId
      );
      let maxIndex: number = items.length - 1;
      let pendingIndex = currentIndex + indexOffset;

      if (pendingIndex <= maxIndex && pendingIndex >= 0) {
        NavigateItemID(items[pendingIndex].id as string);
      } else {
        console.warn(
          "content-context.navigateItemOffset: indexOffset=" +
            indexOffset +
            ", pending=" +
            pendingIndex +
            " OUT OF RANGE (" +
            pendingIndex +
            "/" +
            maxIndex +
            "), ignoring"
        );
      }
    },
    [items, currentItemId]
  );

  return (
    <ContentContext.Provider
      value={{
        items,
        isError,
        language,
        getItemById,
        getCurrentItem,
        getCurrentItemIndex,
        getNextItem,
        getPreviousItem,
        updateCurrentItemById,
        navigateItemOffset,
        NavigateItemID,
        NavigateItemIndex,
        storeLanguage,
      }}
      {...props}
    >
      {children}
    </ContentContext.Provider>
  );
}

export { ContentProvider, useContent };
